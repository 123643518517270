@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: italic;
  font-weight: 600;
  src: url('Metric-SemiboldItalic.woff2') format('woff2'), url('Metric-SemiboldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: italic;
  font-weight: normal;
  src: url('Metric-RegularItalic.woff2') format('woff2'), url('Metric-RegularItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: normal;
  font-weight: normal;
  src: url('Metric-Regular.woff2') format('woff2'), url('Metric-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: normal;
  font-weight: 500;
  src: url('Metric-Medium.woff2') format('woff2'), url('Metric-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: normal;
  font-weight: 600;
  src: url('Metric-Semibold.woff2') format('woff2'), url('Metric-Semibold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'MC';
  font-style: italic;
  font-weight: 500;
  src: url('Metric-MediumItalic.woff2') format('woff2'), url('Metric-MediumItalic.woff') format('woff');
}
